.App {
  margin: 2rem auto;
  max-width: 800px;
}

img {
width: 500px;
height: 500px;
object-fit: contain;
}

.progessBar {
height: 1rem;
width: 0%;
background-color: rgb(68, 212, 231);
color: white;
padding:2px
}

.file-upload {
box-shadow: 2px 2px 2px 2px #ccc;
padding: 2rem;
display: flex;
flex-direction: column;

justify-content: space-between;
font-size: 1rem;
}

input , div , button{
margin-top: 2rem;
}

.upbutton {
 /* width: 5rem; */
 padding: .8rem 1rem;
 background-color: #2767e9;
 color: aliceblue;
 font-size: 1rem;
 cursor: pointer;
 border: none;
}
.upbutton:disabled {
  background-color: gray;
  cursor: not-allowed;
}